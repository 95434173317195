.image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.login-box-container {
  margin-top: 100px;
}
.container-fluid {
  background-color: #f4f5f8 !important;
}
/* .container,
.container-lg,
.container-md,
.container-sm,
.container-xl {
  max-width: 100% !important;
} */
.authent-logo {
  width: 70px;
  height: 70px;
  margin: 0 auto;
  overflow: hidden;
}
.pagination {
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 30px !important;
  margin-bottom: 30px !important;
  padding: 10px;
}
.pagination .previous .page-link {
  background-color: #f44336;
  color: white;
  width: 70px;
  font-size: 20px;
}

/* Styles for the next arrow */
.pagination .previous .page-link {
  background-color: #f44336;
  color: white;
  width: 44px;
  border-radius: 8px;
  font-size: 20px;
}
.pagination .next .page-link {
  background-color: #f44336;
  color: white;
  width: 44px;
  border-radius: 8px;
  font-size: 20px;
}
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
}
/* .page-link  {
  display: inline-block;
  padding: 8px 12px;
  margin: 0 4px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  color: #333;
  cursor: pointer;
  transition: background-color 0.3s ease;
} */

.pagination li {
  display: inline-block;
  margin: 0 5px;
}

.pagination a {
  display: block;
  padding: 5px 10px;
  text-decoration: none;
  color: #000;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.pagination .paging__link--active {
  border: 1px solid #f44336;
  color: #f44336 !important;
  font-weight: bold;
  border-radius: 5px;
  overflow: hidden;
}

.pagination .ellipsis {
  margin: 0 5px;
}
.authent-logo > img {
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.btn-dark,
.btn-dark.disabled,
.btn-dark:disabled {
  background-color: #d1d3e0;
  border-color: #d1d3e0;
  color: #5b5b5b;
}
.btn-dark.focus,
.btn-dark:focus,
.btn-dark:hover,
.btn-dark:not(:disabled):not(.disabled).active,
.btn-dark:not(:disabled):not(.disabled):active {
  background-color: #d1d3e0;
  border-color: #d1d3e0;
  color: #5b5b5b;
  box-shadow: 0 7px 23px -8px #d1d3e0;
}
.btn-link {
  color: #f44336;
  text-decoration: none;
}
.btn-link:hover {
  color: #f44336;
  text-decoration: underline;
}
.btn-outline-primary {
  color: #f44336;
  border-color: #f44336;
}
.review-btn {
  padding: 10px 0;
  background-color: #f44336;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
.review-btn:hover {
  background-color: #da392e;
  color: #fff;
}
.review-btn:active {
  background-color: #f44336;
}
.login-title {
  font-size: 20px;
  font-weight: 600;
  color: #252525;
  margin-top: 20px;
}
.login_sub {
  font-size: 15px;
  color: #7777;
  font-weight: 700;
}
.login-label {
  color: #7777;
  font-weight: 700;
}
.error_ {
  font-size: 13px;
  font-weight: 700;
  color: #eb1c0d;
}
.visible_password{
  position: absolute;
  border: none;
  outline: none;
  background-color: transparent;
  color: #eb1c0d;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}