.error-container {
  position: fixed;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: #eee;
}
.error-content {
  position: absolute;
  max-width: 600px;
  height: auto;
  /* background-color: #fff; */
  border-radius: 10px;
  padding: 10px 20px;
  left: 50%;
  top: 40%;
  transform: translate(-50%, -50%);
}
.err-desc {
  text-align: center;
}
.review-err {
  padding: 7px 30px;
  border-radius: 7px;
  outline: none;
  border: none;
  background-color: #f44336;
  color: #fff;
  margin: 10px;
  margin: 0px auto;
  margin-top: 20px;
}
.review-err:hover {
  box-shadow: inset 0 0 4px #787878;
}
