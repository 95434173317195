.back-ground {
  background-color: #eeebf9;
}

.flex {
  display: flex;
}
.flex-one {
  flex: 0.1;
}
.flex-two {
  flex: 0.9;
}
.pro-img {
  width: 75px;
  height: 75px;
  padding: 5px;
  border-radius: 50%;
}
.card-name {
  padding-left: 5px;
  padding: 2px;
  position: relative;
  top: 10px;
}
.comp-card {
  font-weight: 500;
}
.comp-para {
  font-size: 12px;
}
.card-comment {
  text-align: justify;
  color: gray;
  font-size: 15px;
}
.hov:hover {
  color: #f44336;
}
.pagination {
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 30px;
  padding: 10px;
}
.pagination .previous .page-link {
  background-color: #f44336;
  color: white;
  width: 70px;
  font-size: 20px;
}

/* Styles for the next arrow */
.pagination .previous .page-link {
  background-color: #f44336;
  color: white;
  width: 44px;
  border-radius: 8px;
  font-size: 20px;
}
.pagination .next .page-link {
  background-color: #f44336;
  color: white;
  width: 44px;
  border-radius: 8px;
  font-size: 20px;
}
.back-na {
  float: right;
}
.delete-con {
  width: 40%;
  margin: 0px auto;
  margin-top: 50px;
}
.delete-pa {
  color: red;
  position: relative;
  cursor: pointer;
}
.float-right {
  float: right;
  font-weight: 600;
  position: relative;
  margin-left: 10px;
}
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
}
.nothing_to_show {
  text-align: center;
  color: #7777;
  padding: 120px 0;
}
.ban_btn {
  position: absolute;
  border: none;
  outline: none;
  padding: 7px 16px;
  background-color: #f44336;
  color: #eeebf9;
  font-weight: 500;
  right: 10px;
  border-radius: 5px;
  font-size: 13px;
  overflow: hidden;
  z-index: 9;
  transition: all 0.2s ease-in-out;
}
.ban_btn:hover {
  background-color: #d73c31;
}
.ban_btn:disabled {
  background-color: #d77770;
}
.review-badge {
  padding: 5px 8px;
  text-align: center;
  width: 100px;
  border-radius: 8px;
  font-weight: 500;
  font-size: 13px;
  margin: 0 auto;
  display: block;
}
.review-badge.active {
  background-color: #e2e3e5;
  color: #383d41;
}
.review-badge.banned {
  background-color: #f4433649;
  color: #721c24;
}
.unban_btn {
  position: absolute;
  border: none;
  outline: none;
  padding: 7px 16px;
  background-color: #1e7a34;
  color: #eeebf9;
  font-weight: 500;
  right: 10px;
  border-radius: 5px;
  font-size: 13px;
  overflow: hidden;
  z-index: 9;
  transition: all 0.2s ease-in-out;
}
.unban_btn:hover {
  background-color: #155724;
}
.unban_btn:disabled {
  background-color: #a1b5a6;
}
.edit_profile {
  position: absolute;
  right: 10px;
  bottom: 10px;
  background-color: transparent;
  border: none;
  outline: none;
  color: #d73c31;
  font-weight: 600;
  font-size: 13px;
}
.submit_btn {
  outline: none;
  border: none;
  background-color: #f44336;
  color: #fff;
  padding: 5px 18px;
  border-radius: 5px;
  margin: 10px 0;
  font-size: 14px;
  font-weight: 600;
  transition: all 0.2s ease-in-out;
}
.submit_btn:hover {
  background-color: #d73c31;
}
.submit_btn:disabled {
  background-color: #d77770;
}
.inp_file {
  display: none;
}
.search-bar {
  width: 35%;
  float: right;
}
