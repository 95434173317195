.verified_customer_section {
  display: grid;
  grid-template-columns: 1fr;
  border-radius: 10px;
  padding: 0 10px;
}
.rounded-img {
  width: 80px;
  height: 80px;
}
.customer-review {
  color: gray;
  font-size: 14px;
}
.image_review {
  padding-top: 10px;
  padding-left: 5px;
}

.image_review i {
  font-size: 16px;
  color: gold;
  margin-left: 0;
  margin-right: 2px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.customer_image {
  max-width: 80px;
  max-width: 80px;
}
.comp-name {
  font-size: 22px;
  line-height: 1.2rem;
}
.comp-des {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.date_ {
  position: absolute;
  font-size: 14px;
  font-weight: 600;
  color: #a0a8c3;
  right: 10px;
  bottom: 10px;
  user-select: none;
}
.customer_image img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  float: left;
  margin-right: 20px;
  object-fit: cover;
}

.customer_name {
  font-family: Poppins;
  font-weight: 700;
  font-size: 16px;
  margin-top: 0;
  text-align: left;
  color: #2e2a39;
  margin-bottom: 0px;
}
.customer_mail {
  font-size: 13px;
  margin-bottom: 0px;
  margin-left: 10px;
  padding: 3px;
  color: #a0a8c3;
  user-select: none;
}
.card_info_btn {
  border: none;
  outline: none;
  color: #f44336;
  background-color: #fff;
}
.review_info_ span {
  color: rgb(65, 49, 49);
}
.customer_bage {
  float: left;
  padding-right: 5px;
}

.customer_status .customer_bage img {
  width: 12px;
  height: 15px;
}

.customer_status_content {
  padding-left: 5px;
  margin-bottom: 0;
  font-family: Poppins;
  font-weight: 400;
  font-size: 13px;
  margin-top: 2px;
  color: rgb(91, 191, 138);
}

.customer_comment {
  padding-left: 5px;
  padding-top: 10px;
  margin-bottom: 0;
  font-size: 15px;
  color: #2e2a39;
  margin-top: 3px;
  font-family: Poppins;
  font-weight: 300;
  padding-right: 5px;
  text-align: left;
  font-style: italic;
}
.comp_category {
  margin-bottom: 5px;
  font-size: 15px;
  font-weight: 600;
  color: #747474;
}
.comp_category > span {
  font-weight: 400;
  font-size: 13px;
}
.user_connection {
  font-size: 13px;
  margin-bottom: 10px;
  color: #747474;
  font-weight: 600;
  text-transform: capitalize;
}
.review_data {
  width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.comment {
  border: 1px solid #eee;
  padding: 10px;
  border-radius: 5px;
  background-color: #eee;
}
.comment > textarea {
  width: 100%;
  min-height: 150px;
  height: 100%;
  outline: none !important;
  border: none !important;
  background-color: transparent;
}
.comp_connection {
  font-weight: 500;
  color: #747474;
}
.modal_btn {
  width: 100%;
  margin: 10px 0 0 0;
  border: none;
  padding: 5px 10px;
  outline: none;
  border-radius: 5px;
  transition: all 0.2s ease-in-out;
  color: #ffff;
  background-color: #747474;
  overflow: hidden !important;
}
.approve_btn:active {
  transform: scale(1.1);
}

.cancel_btn:hover {
  background-color: #fb4336;
}
.approve_btn:hover {
  background-color: #008000;
}

.visit_profile:hover {
  background-color: #004095;
}
.approve_btn:disabled {
  background-color: #7777;
}
.approve_btn:disabled:active {
  transform: scale(1);
}
.save_cmnt {
  background-color: #469c46;
}
.save_cmnt:hover {
  background-color: #116411;
}
.save_cmnt:disabled {
  background-color: #88ad88;
}
.approved_ {
  color: #008000;
  font-size: 18px;
}
.pending_ {
  color: #747474;
  font-size: 18px;
  text-align: center;
}
.disabled_ {
  color: #f44336;
  font-size: 18px;
  text-align: center;
}
.approved_status {
  color: #008000;
  font-weight: 600;
}
.edit_cmnt {
  border: none;
  outline: none;
  background-color: transparent;
  color: #fb4336;
  float: right;
  font-size: 18px;
}
.disable_btn {
  outline: none;
  border-radius: 5px;
  background-color: transparent;
  transition: all 0.2s ease-in-out;
  margin-bottom: 10px;
  border: none;
}
.disable_btn:hover {
  background-color: #f44336;
  color: #eee !important;
}
.disable_btn > i {
  font-size: 15px;
  padding: 0 3px;
}
.disable_btn.enable {
  border: 1px solid #f44336;
  color: #f44336;
}
.disable_btn.disabled {
  background-color: #f44336;
  color: #ffff;
}
.flexe {
  justify-content: space-between;
}
.info_area {
  float: right !important;
  margin-top: 29px;
}
