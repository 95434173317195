.category-img {
  height: 28px;
}
.image_preview {
  display: block;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background-color: #eee;
  margin: 0 auto;
  overflow: hidden;
}
.data{
  margin-top: 45px;
}
.flex-cont{
  display: flex;
  justify-content: space-between;

}
.btn-add{
  padding: 3px;
  width: 70px;
  margin-bottom: 10px;
  color: white;
  font-weight: 600;
  background-color: #f44336;
}
.flex-end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.placeho{
  padding: 20px;
}
.flex-start {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.image_preview > img{
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    width: 100%;
    height: 100%;
}.tab-back{
  background-color: white;
  padding: 10px !important;
  border-radius: 6px;
}
.edit-icon{
  color: green;
}
.btn-nav{
  float: right;
  border: none;
  padding: 5px;
  outline: none;
  position: relative;
  top: -40px;
  width: 100px;
  border-radius: 6px;
  right: 112px;
}