.navbar {
  position: fixed;
  width: 100%;
  background-color: #59c4bc;
  height: 50px !important;
  border-bottom: 1px solid rgba(204, 204, 204, 0.467);
  overflow: hidden;
  z-index: 10;
  float: left;
  text-align: left;
}
.logo-svg {
  padding: 10px;
  width: 38%;
  text-align: center;
  justify-content: center;
}
.head {
  color: #252525;
}
.footered {
  margin-top: auto;
  height: 50px;
  padding: 10px 0;
  top: 235px;
  text-align: justify;
  color: black;
  background-color: rgb(240, 191, 191);
  text-align: center;
}

.side-bar {
  position: fixed;
  color: gray;
  float: left;
  margin-top: 47px;
  width: 250px;
  min-height: 91%;
  background-color: #fff;
  box-shadow: 0 2px 5px #94919177;
  transition: all 0.2s linear;
  z-index: 99;
  left: 0;
  margin-top: 10 px;
  overflow: hidden;
  border-radius: 12px;
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.nav-logo {
  width: 150px;
  height: 150px;
  margin: 0 auto;
  display: block;
  padding: 10px;
}
.nav-logo > img {
  max-width: 100%;
}
.nav-logo > label {
  align-items: center;
  justify-content: center;
}
.side-bar > ul {
  list-style: none;
  padding: 0;
  color: #59c4bc;
}
.side-bar ul li {
  position: relative;
  width: 100%;
  padding: 10px 0;
  transition: all 0.5s ease-in-out;
}
.side-bar > i {
  position: absolute;
  right: 10px;
  color: black;
  cursor: pointer;
}
.nav-links {
  position: relative;
  margin: 10px 0;
  padding: 0 8px;
  color: black;
  font-size: 15px;
  text-decoration: none;
  white-space: nowrap;
}
.nav-links i {
  font-size: 18px !important;
}
.backgrd {
  /* background-color: #fff; */
  margin: 0 !important;
  padding: 0 !important;
  border-radius: 12px;
}
.head {
  background-color: #fcfcfc;
  height: 50px;
  z-index: 9999;
  padding: 0 !important;
  --bs-gutter-x: 0rem;
  --bs-gutter-y: 0;
  margin: 0 !important;
}
.headers {
  background-color: #f4f5f8;
  position: relative;
  /* left: -66px; */
  justify-content: center;
  margin-right: 10px;
  margin-bottom: 10px;
  align-items: center;
}

.log {
  border: none;
  background-color: transparent;
  padding-right: 15px;
  margin: 2px;
  margin-left: 10px;
}
.log > span {
  font-weight: 500;
}
.back-btn {
  padding: 3px;
  width: 70px;
  margin-bottom: 10px;
  border-radius: 6px;
  border: none;
  color: white;
  font-weight: 700;
  background-color: #f44336;
}
.head i {
  float: right;
  position: relative;
  right: 70px;
}
.nav-links:hover {
  color: #7b8676;
}
.nav-links > i {
  padding-right: 25px;
  padding-left: 10px;
  font-size: 25px;
}
.links.active {
  background-color: #f44336;
}
.head-but {
  padding: 6px;
  padding-right: 20px;
}
.links.active > .nav-links {
  color: #ffff;
}

.links > .tooltip_ {
  opacity: 0;
  position: absolute;
  color: #000;
  z-index: 99;
  font-size: 12px;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  transition: opacity 0.2s ease-in-out;
  cursor: pointer;
}
.tooltip_.active {
  color: #fff;
}

.dropdown {
  display: none;
  /* Add your dropdown menu styles here */
}

.dropdown.show {
  display: block;
  /* Add any additional styles when the menu is shown */
}
.user_ {
  position: absolute;
  width: 37px;
  height: 37px;
  right: 20px;
  border-radius: 50%;
  cursor: pointer;
  justify-content: center;
  overflow: hidden;
}
.user_ Button {
  width: 100px !important;
  margin: 0 auto;
  margin-left: -31px;
  margin-top: -5px;
  height: 50px !important;
  font-size: 20px;
}
.bars {
  width: 50px;
  height: 50px;
  position: relative;
  font-size: 30px;
  padding: 0 20px;
  color: #95a28f;
  cursor: pointer;
}
#check {
  position: absolute;
  opacity: 1;
  top: 0;
  cursor: pointer;
  left: 50%;
}
.nav-logo > #logoImg2 {
  display: none;
}
.profile-img {
  width: 60px;
  height: 60px;
  position: relative;
  left: 10px;
  border-radius: 50%;
}
.mob_logo {
  width: 120px;
  height: 100%;
  /* background-color: #252525; */
  margin: 0 auto;
  display: block;
  background-size: cover;
  display: none;
  transition: all 0.2s ease-in-out;
}
.dropdown-select {
  /* Add your styles for the dropdown select element here */
  padding: 5px;
  font-size: 16px;
  border-radius: 4px;
  border: none;
  height: 30px;
  border-radius: 6px;
  color: #fff;
  text-align: center;
  margin: 5px;
}
.dropdown-select option {
  background-color: white;
  color: black;
}
:ho .dropdown-select option a {
  background-color: white;
  color: black;
}
/* Additional styles for the dropdown arrow */
.dropdown-select::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  width: 0;
  background-color: white;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #888;
}

.flex-box {
  display: flex;
  color: rgb(112, 108, 108);
}
.box-1 {
  flex: 0.3;
}
.head-low {
  position: relative;
  top: -28px;
  font-size: 14px;
}
.box-2 {
  flex: 0.5;
}
.img-area {
  padding: 10px;
}
.head-main {
  padding-top: 20px;
}
/* Responsive */
@media screen and (max-width: 1200px) {
  .nav-logo {
    display: none;
  }
  .side-bar {
    width: 70px;
  }
  .side-bar ul li {
    padding: 20px;
    margin-top: 10px;
  }
  .links:hover > .tooltip_ {
    opacity: 1;
  }
  .nav-logo > #logoImg1 {
    display: none;
  }
  .mob_logo {
    display: block;
  }
  .nav-links > i {
    padding-left: 0;
  }
}

.react-confirm-alert-button-group > button {
  width: 100px;
  height: 35px;
  font-weight: 500;
  font-size: 20px !important;
  padding: 0px !important;
}
.react-confirm-alert-overlay {
  background: #95a28f;
}
.clear-button {
  background-color: #252525;
}
.confirm-btn {
  background-color: #95a28f !important;
}
.user-account {
  width: 100%;
  color: gray;
}
strong {
  color: gray;
}
.client-drop {
  display: flex;
}
