html, body{
  background-color: #f4f5f8;
}

textarea:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
input[type="file"]:focus,
input[type="radio"]:focus,
input[type="checkbox"]:focus textarea,
.form-check-input:focus,
.form-control:focus,
.uneditable-input:focus {
  border-color: #a1a1a1 !important;
  box-shadow: none !important;
  outline: 0 none !important;
}
