
.button-comp {
  margin-bottom: 20px;
}
.required_{
    color: red;
}
.data{
  margin-top: 45px;
}
@media (max-width: 1300px) {
  .add-category {
    /* margin-left: 50px !important; */
    margin: 0px auto !important;
  }
  .head-space {
    margin-bottom: 50px;
  }
}
@media (max-width: 100px) {
  .add-category {
    /* margin-left: 50px !important; */
    margin: 0px auto !important;
    width: 95%;
  }
  .head-space {
    margin-bottom: 50px;
  }
}
@media (max-width: 600px) {
  .content {
    margin-left: 37px !important;
    overflow-x: scroll;
  }
}
.edit_card{
  width: 50%;
}