.business-table {
  margin: 0 auto;
}
.profile_img {
  height: 50px;
  width: 50px;
  overflow: hidden;
}
.profile_img > img {
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  height: 100%;
}
.button-profile {
  position: relative;
  float: right;
}

.profile_description > p {
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.action_ {
  font-size: 22px;
  text-align: center;
}
.action_btn {
  border: none;
  outline: none;
  background-color: #fff;
  color: #f44336;
  border-radius: 50%;
  padding: 3px 10px;
  transition: all 0.2s ease-in-out;
}
.action_btn:hover {
  background-color: #eee;
}
.action_btn:focus {
  animation: wiggle 0.1s linear;
}
.edit-title {
  display: flex;
  align-items: center;
}

.start-edit {
  flex: 0.5;
}
/* 
.center-edit {
} */
.review_card {
  padding: 20px;
  margin: 0 auto;
}
.edit_description {
  height: 100px;
}
.address-container {
  position: relative;
}
.review_add_address_btn {
  position: absolute;
  margin: 10px 0;
  background-color: #ffcdca;
  border: none;
  outline: none;
  border-radius: 5px;
  font-size: 20px;
  right: 10px;
  padding: 0 15px;
  transition: all 0.2s ease-in-out;
}
.review_add_address_btn:hover {
  background-color: #f0857d;
}
.review_btn_remove_address {
  position: relative;
  width: 50px;
  background-color: transparent;
  color: #f44336;
  border: none;
  outline: none;
  border-radius: 50%;
  padding: 10px 5px;
  right: 10px;
  margin-bottom: 10px;
  transition: all 0.2s ease-in-out;
}
.review_btn_remove_address:hover {
  background-color: #eee;
}
.review-label {
  font-weight: 600;
  font-size: 13px;
  margin: 0;
}
.profile_delete_btn {
  border: none;
  outline: none;
  background-color: #f44336;
  color: #fff;
  margin: 0 10px;
  padding: 0 10px;
  border-radius: 5px;
  transition: all 0.2s ease-in-out;
}
.profile_delete_btn:hover {
  background-color: #e13e33;
}
.button-for-add {
  position: relative;
  right: 65px;
  float: right;
  top: -20px;
}
@keyframes wiggle {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
.add_business_card {
  width: 60%;
}

@media (max-width: 1300px) {
  .buttons {
    right: 0px;
  }
  .add_business_card {
    width: 70%;
  }
}
@media screen and (max-width: 768px) {
  .add_business_card {
    width: 75%;
  }
}
@media (max-width: 600px) {
  .per-table {
    overflow-x: scroll;
  }
  .add_business_card {
    width: 100%;
  }
}
.postal_code {
  text-transform: uppercase;
}
.postal_code::placeholder {
  text-transform: capitalize;
}
.ql-editor {
  min-height: 250px;
}
.error {
  color: red;
  font-size: 12px;
}
