.react-tabs__tab {
  width: 33.33%;
  margin-top: 35px !important;
  background-color: #f0bfbf;
  border: none;
  text-align: center;
  color: #f44336;
  font-weight: 600;
}
.react-tabs__tab--selected {
  background: #fff;
  border: 1px solid #803636;
  color: #f44336;
  border-radius: 5px 5px 0 0;
}
.last-box {
  background-color: #fff !important;
  color: #585656;
  /* margin-top: 30px; */
  border-radius: 4px;
}
.settings_home {
  font-size: 22px;
  padding: 10px;
  font-weight: 700;
  width: 50%;
  color: #202020;
  margin-top: 20px;
  border-radius: 4px;
  text-align: center;
  border: 1px solid #e1e1e1;
}

.sec_1 {
  color: #585656;
  margin-bottom: 20px;
  font-size: 25px;
  width: 100%;
  padding: 3px;
  font-weight: 600;
  border: 1px solid #e1e1e1;
  margin-top: 20px;
  text-align: left;
}
.sec_1_para {
  border: 1px solid #e1e1e1;
  color: #585656;
  font-size: 15px;
  padding: 10px;
  width: 100%;
  text-align: justify;
  height: 300px !important;
}
.sec_1_para::placeholder {
  text-align: justify;
}
.btn-add {
  float: right;
  border: none;
  border-radius: 4px;
}
.about_text {
  width: 100%;
  border: 1px solid #e1e1e1;
  border-radius: 4px;
  padding: 10px;
}
.about-content {
  font-weight: 400;
  padding: 30px;
  padding-top: 50px;
  text-align: justify;
}
.back-box {
  margin-top: 50px;
  background-color: #fb4336;
  height: 500px;
  width: 60%;
}
.last {
  padding-bottom: 150px;
}
.contents {
  padding: 50px;
  min-height: 500px;

  border-radius: none;
  margin-top: 30px;
  padding-bottom: 20px;
}
.head-card {
  font-weight: 600;
  border: 1px solid #e1e1e1;
  width: 100%;
  border-radius: 4px;
  padding: 10px;
  color: #000;
  font-size: 24px;
  margin-bottom: 20px;
  font-weight: 700;
  letter-spacing: 1px;
}
.card-para {
  text-align: justify;
  width: 99%;
  border-radius: 4px;
  overflow-y: scroll;
  overflow-x: hidden;
  min-height: 100px;
  padding: 10px;
  border: 1px solid#e1e1e1;
  font-size: 14px;
}
.card-para::-webkit-scrollbar {
  width: 7px;
  height: 7px !important;
}
.card-para::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px #f5f7fb;
}
.card-para::-webkit-scrollbar-thumb {
  background-color: #c4d4bc;
  outline: #95a28f;
}
.about_para {
  border: 1px solid #e1e1e1;
  background-color: #fff;
}
.update_Btn {
  background-color: #f7591f;
  color: #fff;
  border-radius: 4px;
  margin-top: 20px !important;
  padding: 6px;
  border: none;
  margin: 0px auto;
  width: 150px;
}
.update_Btn:disabled {
  background-color: #ca795b;
}
.remove {
  float: right;
  color: red;
  margin-bottom: 10px;
  font-size: 15px;
  position: relative;
  right: 10px;
  bottom: 10px;
  cursor: pointer;
}
.que_imn {
  width: 100%;
  border: 1px solid #e1e1e1;
  padding: 5px;
  border-radius: 4px;
}
.dele-qu {
  text-align: right;
  color: red;
  position: relative;
  right: 10px;
  float: right;
}
.card-ans {
  padding: 10px;
  min-height: 120px;
  border: 1px solid #e1e1e1;
  height: auto;

  border-radius: 4px;
}
.card-add {
  padding: 10px;
  min-height: 50px;
  border: 1px solid #e1e1e1;
  width: 100%;
  height: auto;

  border-radius: 4px;
}
.card-ans::-webkit-scrollbar {
  width: 7px;
  height: 7px !important;
}
.card-ans::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px #f5f7fb;
}
.card-ans::-webkit-scrollbar-thumb {
  background-color: #c4d4bc;
  outline: #95a28f;
}
.pulished_{
  /* float: right; */
  margin: 10px 0;
}