.background {
  background-color: #f4f5f8;
}
.mains {
  background-color: #f4f5f8;
  padding: 15px;
  height: 90vh;
  padding-left: 280px;
}
.main {
  background-color: #f4f5f8;
  padding: 15px;
  height: 93vh;
}
.dash-head {
  color: #7d3b48;
  text-transform: uppercase;
  font-size: 18px;
  position: relative;
}

a {
  text-decoration: none;
  color: rgb(70, 66, 66);
}
a:hover {
  color: #fb4336;
}
.dashboard_protest {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  font-weight: 500;
}
.dash_table {
  /* width: 80%; */
  /* margin: 0 auto; */
}
#comment {
  width: 50%;
}
#comment > p {
  margin-bottom: 0px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.view_more {
  width: 100px;
  height: 30px;
  position: absolute;
  top: 10px;
  right: 10px;
  border-radius: 10px;
  border: none;
  outline: none;
  background-color: #f5c8c4;
  color: #252525;
  font-weight: 500;
  font-size: 13px;
  transition: all 0.2s ease-in-out;
}
.view_more:hover {
  background-color: #fb4336;
  color: #f4f5f8;
}
@media (max-width: 1300px) {
  .main {
    padding-left: 270px !important;
    height: auto;
  }
  .mains {
    padding-left: 280px !important;
    height: auto;
  }
}
@media (max-width: 1200px) {
  .logo {
    width: 100% !important;
  }
  .log > span {
    font-weight: 500;
    display: none;
  }
  .main {
    padding-left: 100px !important;
  }
  .mains {
    padding-left: 100px !important;
  }
}
@media (max-width: 986px) {
  .logo {
    width: 100%;
  }
}
